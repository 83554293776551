<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Assignment
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs4>
                                <v-calendar-field
                                        id-val="frmDate"
                                        v-model="frmDate"
                                        label="From Date">
                                </v-calendar-field>
                            </v-flex>
                            <v-flex xs4>
                                <v-calendar-field
                                        id-val="toDate"
                                        v-model="toDate"
                                        label="To Date">
                                </v-calendar-field>
                            </v-flex>

                            <v-flex xs4>
                                <v-select :items="subjectResponse" :disabled="subjectResponse.length<1" class="pa-0"
                                          label="Subject"
                                          :loading="subjectLoading"
                                          v-model="filter_subject" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :options.sync="pagination" :server-items-length="form.items.meta.total"
                                  :loading="form.loading">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td>
                                    <span v-if="item.documents.length > 0">
                                        <span v-for="(x ,i) in item.documents"
                                              :key="i">
                                            <a target="_blank" :href="x.docs"
                                               style="text-decoration: none">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on">attach_file</v-icon>
                                                    </template>
                                                    <span>Click to download doc.</span>
                                                </v-tooltip>
                                            </a>
                                            </span>
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                                <td class="text-xs-left">{{ item.title }} <br>
                                    <small style="color:#777;">{{item.description}}</small>
                                </td>
                                <td class="text-xs-left">{{ item.subject ? (item.subject.name ?
                                    item.subject.name.toUpperCase():'-'):'-' }}
                                </td>
                                <td class="text-xs-left">{{ item.teacher ? (item.teacher.name ?
                                    item.teacher.name :'-'):'-' }}
                                </td>
                                <td class="text-xs-left">
                                    <v-chip small label color="primary" text-color="white">
                                        {{ item.assignment_date }}
                                    </v-chip> &nbsp;
                                    <v-chip small label color="orange" text-color="white">
                                        {{ item.submission_date }}
                                    </v-chip>
                                </td>
                                <td class="text-right">
                                    -
                                    <!--<view-button v-if="today >= item.submission_date"-->
                                                 <!--permission="assignment-update&assignment-read"-->
                                                 <!--@click.native="viewAssignemnt(item.id)"/>-->
                                    <!--<edit-button permission="assignment-update"-->
                                                 <!--@agree="updateAssignment(item)"/>-->
                                    <!--<delete-button permission="assignment-delete" @agree="form.delete(item.id)"/>-->
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <v-pagination v-if="form.items.data.length>=10" v-model="pagination.page"
                                      :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins'
    import {validateDate} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    let today = new Date();
    let tomorrow = today.setDate(today.getDate() + 1);

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            isLoading: false,
            form: new Form({
                title: '',
                description: '',
                document: '',
                assignment_id: '',
                section_id: '',
                teacher_id: '',
                subject_id: '',
                submission_date: new NepaliDate(new Date(tomorrow)).format('YYYY-MM-DD'),
                assignment_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
                status: 0,
            }, '/api/assignment'),
            search: null,
            multipleFiles: [],
            filter_subject: '',
            pagination: {
                rowsPerPage: 25
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            headers: [
                {text: '#', align: 'left', value: 'id', width: 20, sortable: false},
                {text: 'Doc.', align: 'left', value: 'id', width: 10, sortable: false},
                {text: 'Title', align: 'left', value: 'title', width: 300, sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false, width: 170},
                {text: 'Assignment/Submission Date', align: 'left', value: 'assignment_date'},
                // {text: 'Status', align: 'left', value: 'status', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            sections: [],
            grades: [],
            grade: '',
            section: '',
            subjects: [],
            filter_subjects: [],
            subjectLoading: false,
            frmDate: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            toDate: new NepaliDate(new Date(tomorrow)).format('YYYY-MM-DD'),
            teachers: [],
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            today: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            assignmentSubjects: [],
            subjectResponse: []
        }),
        computed: {
            ...mapState(['batch', 'guardian']),
            items() {
                return this.teachers.map(entry => {
                    return Object.assign({}, entry, {name: entry.name})
                })
            },
            getSelectedTeacher() {
                let teacher = {};
                if (this.teachers.length) {
                    let $this = this;
                    this.teachers.map(function (data) {
                        if ($this.form.teacher_id === data.id) {
                            teacher = data;
                        } else if ($this.update) {
                            teacher = data;
                        }
                    });
                }
                return teacher;
            }
        },
        mounted() {
            const student = JSON.parse(this.$storage.get('_s_ch'));
            this.grade = student.grade_id;
            this.section = student.section_id;
        },
        watch: {
            'pagination': function () {
                this.get();
            },
            'guardian.selectedChild': function (value) {
                this.grade = value.grade_id;
                this.section = value.section_id;
            },
            'filter_subject': function () {
                this.get();
            },
            'frmDate': function () {
                this.get();
            },
            'toDate': function () {
                this.get();
            },
            'grade': function () {
                this.getSubjects();
            }
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&sectionId=' + this.section.toString() + '&frmDate=' + this.frmDate.dateForm() + '&toDate=' + this.toDate.dateForm() + '&subId=' + this.filter_subject.toString() || '0';
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            get(params) {
                if (this.section && this.frmDate && this.toDate) {
                    let selectedSubjects = [];
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    return this.form.get(null, query).then(({data}) => {
                        data.data.map(function (item) {
                            selectedSubjects.push(item.subject_id);
                        });
                        this.assignmentSubjects = selectedSubjects;
                        this.pagination.totalItems = data.meta.total
                    })
                }
            },
            getSubjects() {
                this.filter_subjects = [];
                this.$rest.get('/api/subject?rowsPerPage=50&grade=' + this.grade + '&section=' + this.section).then(({data}) => {
                    this.subjectLoading = false;
                    this.subjectResponse.push({value: '', text: 'All'});
                    let $this = this;
                    data.data.map(function (item) {
                        $this.subjectResponse.push({
                            value: item.id,
                            text: item.name.toUpperCase(),
                            teachers: item.teachers
                        });
                    });
                    this.populateSubjects();
                })
            },

            populateSubjects() {
                let $this = this;
                this.filter_subjects = [];
                this.subjectResponse.map((item) => {
                    if (!$this.assignmentSubjects.includes(item.value)) {
                        if (item.value !== '') {
                            $this.filter_subjects.push({value: item.value, text: item.text});
                        }
                    }
                });

                if (this.form.subject_id) {
                    $this.filter_subjects.push({value: $this.form.subject.id, text: $this.form.subject.name})
                }
            }

        }
    }
</script>
<style lang="scss" scoped></style>